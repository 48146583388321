import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_mobile_dummy = _resolveComponent("mobile-dummy")!
  const _component_UserAgreement = _resolveComponent("UserAgreement")!
  const _component_reset_password_view = _resolveComponent("reset-password-view")!
  const _component_auth_view = _resolveComponent("auth-view")!
  const _component_base_layout = _resolveComponent("base-layout")!
  const _component_onboarding = _resolveComponent("onboarding")!
  const _component_messages = _resolveComponent("messages")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.isMobile)
      ? (_openBlock(), _createBlock(_component_mobile_dummy, { key: 0 }))
      : (_ctx.$route.name === 'UserAgreement')
        ? (_openBlock(), _createBlock(_component_UserAgreement, { key: 1 }))
        : (_ctx.$route.name === 'ResetPassword')
          ? (_openBlock(), _createBlock(_component_reset_password_view, { key: 2 }))
          : (!_ctx.token)
            ? (_openBlock(), _createBlock(_component_auth_view, { key: 3 }))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createVNode(_component_base_layout),
                (_ctx.showOnboarding)
                  ? (_openBlock(), _createBlock(_component_onboarding, { key: 0 }))
                  : _createCommentVNode("", true)
              ], 64)),
    _createVNode(_component_messages)
  ], 64))
}