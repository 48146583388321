
import { defineComponent, defineAsyncComponent } from 'vue';
import BaseLayout from '@/components/layout/BaseLayout.vue';
import AuthView from '@/views/auth/AuthView.vue';
import MobileDummy from '@/views/MobileDummy.vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { useIsMobile } from '@/composables/useIsMobile';
import ResetPasswordView from '@/views/reset-password/ResetPasswordView.vue';
import Messages from '@/components/messages/Messages.vue';
import UserAgreement from '@/views/user-agreement/UserAgreement.vue';

interface ComponentData {
  showOnboarding: boolean;
}

export default defineComponent({
  name: 'App',

  components: {
    Messages,
    ResetPasswordView,
    BaseLayout,
    AuthView,
    MobileDummy,
    UserAgreement,
    Onboarding: defineAsyncComponent(() => import('@/components/onboarding/Onboarding.vue')),
  },

  setup() {
    const { isMobile, observeResize } = useIsMobile();

    return {
      isMobile,
      observeResize,
    };
  },

  async mounted() {
    this.observeResize(true);
    await this.start();
    setTimeout(() => {
      this.showOnboarding = true;
    }, 1500);
  },

  computed: {
    ...mapState('user', ['info', 'token']),
    ...mapGetters('message', ['messages']),
  },

  data(): ComponentData {
    return {
      showOnboarding: false,
    };
  },

  methods: {
    ...mapActions(['start']),
  },
});
